import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { logout, setToken } from "../store/reducers/authSlice";
import { auth } from "../firebase/firebase.config";
import { signOut } from "firebase/auth";

//base query that will attach the auth header to every request
const baseQuery = fetchBaseQuery({
  // baseUrl: "http://localhost:8080/api/v1",
  baseUrl: process.env.NODE_ENV === "production" ? process.env.REACT_APP_API_URL : "http://localhost:8080/api/v1",
  //   credentials: "include",                    //for httponly cookies
  prepareHeaders: (headers, { getState }) => {
    const token = getState().auth.token.accessToken;
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result.error && result.error.status === 401) {
    const refreshResult = await auth.currentUser.getIdToken();

    if (refreshResult) {
      api.dispatch(setToken({ accessToken: refreshResult }));

      // retry the initial query
      result = await baseQuery(args, api, extraOptions);
    } else {
      await signOut(auth);
      api.dispatch(logout());
    }
  }
  if (result.error && result.error.status === 403) {
    await signOut(auth);
    api.dispatch(logout());
  }
  return result;
};

export const apiSlice = createApi({
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({}),
});
