import React, { useEffect, useLayoutEffect, useReducer } from 'react';
import ModuleHeader from './moduleHeader/ModuleHeader';
import {
  CardActions,
  CardContent,
  Grid,
  Typography,
  Button,
  Card,
  MenuItem,
  Box,
  TextField,
  Divider,
  FormControl,
  Checkbox,
} from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { Field, FieldArray, Form, Formik } from 'formik';
import CustomSelect from './customSelect/CustomSelect';
import _ from 'lodash';

import countryData from './../pages/data1.json';
import { toast } from 'react-toastify';
import {
  useLazyGetSingleBookingQuery,
  useSendBookingMutation,
} from './../apis/bookingApis/bookingApi';
import { useSelector } from 'react-redux';
import { getAllCurrencies } from './../app/store/reducers/currencySlice';
import {
  useGetAllLocationsQuery,
  useGetAllVisasQuery,
} from './../apis/locationsApi/locationsApi';
import { useCreateItineraryMutation } from '../apis/itineraryApis/itineraryApi';

const TOAST_CONFIG = {
  position: 'top-right',
  autoClose: 2000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: 'light',
};

const getActivityPrice = (activity, transport, noa, noc) => {
  if (['WOT', 'PT', 'ST'].includes(transport)) {
    let cost;
    if (transport === 'WOT') {
      cost =
        activity.price[transport]['AdultCost'] * noa +
        activity.price[transport]['ChildCost'] * noc;
      return cost;
    } else if (transport === 'ST') {
      cost =
        (activity.price[transport]['AdultCost'] +
          activity.price['WOT']['AdultCost']) *
          noa +
        (activity.price[transport]['ChildCost'] +
          activity.price['WOT']['ChildCost']) *
          noc;
      return cost;
    } else {
      let totalPrice = 0;
      let zerosArray = Array.from({
        length: activity.price.PT.length,
      }).fill(0);
      let maxPassengersArray = activity.price.PT.map((obj) => [
        obj.maxPassengers,
        obj.price,
      ]);
      maxPassengersArray.sort((x, y) => x[0] - y[0]);
      let totalPassengers = noa + noc;
      while (totalPassengers > 0) {
        let i = 0,
          flag = 0;
        while (i < maxPassengersArray.length) {
          if (parseInt(maxPassengersArray[i][0]) > totalPassengers) {
            zerosArray[i] = zerosArray[i] + 1;
            flag = 1;
            break;
          } else if (i == maxPassengersArray.length - 1) {
            zerosArray[i] = zerosArray[i] + 1;
            break;
          }
          i = i + 1;
        }
        if (flag) {
          totalPassengers = -1;
        } else {
          totalPassengers =
            totalPassengers -
            parseInt(maxPassengersArray[maxPassengersArray.length - 1][0]);
        }
      }
      for (let i = 0; i < maxPassengersArray.length; i++) {
        totalPrice =
          totalPrice + zerosArray[i] * parseInt(maxPassengersArray[i][1]);
      }
      totalPrice =
        totalPrice +
        activity.price['WOT']['AdultCost'] * noa +
        activity.price['WOT']['ChildCost'] * noc;
      return totalPrice;
    }
  }
  return 0;
};

const CustomizeItinerary = ({
  currentUser,
  data,
  activityData,
  itineraryId,
}) => {
  const formRef = React.useRef();
  const checkboxRef = React.useRef(null);
  const currencies = useSelector(getAllCurrencies);

  const [trigger] = useLazyGetSingleBookingQuery();
  const [sendBooking] = useSendBookingMutation();

  const [createItinerary] = useCreateItineraryMutation();
  const { data: allVisas, isLoading: isVisaLoading } = useGetAllVisasQuery();
  const { data: allLocations } = useGetAllLocationsQuery({ keyword: '' });

  const INITIAL_VALUES = {
    itineraryName: '',
    description: data.description || '',
    location: data.location || '',
    country: data.country || '',
    NoOfNights: data.days.length - 1,
    visaStatus: data.visaStatus,
    numberOfAdults: data.AdultCount,
    numberOfChildren: data.ChildCount,
    numberOfInfants: data.InfantCount,
    tour_start_date: addDaysToDate(data.startDate, 0),
    tour_end_date: addDaysToDate(data.endDate, 0),
    images: data.images,
    rating: '',
    markup_amount: 0.0,
    hotels: [{ id: '', start: '', end: '', rooms: [''] }],
    days: [],
  };

  const numberOfAdultsArray = Array.from({ length: 30 }, (_, index) => index);
  const numberOfChildArray = Array.from({ length: 25 }, (_, index) => index);
  const numberOfInfantArray = Array.from({ length: 10 }, (_, index) => index);

  function addDaysToDate(inputDate, numDays) {
    // Splitting the input date string into year, month, and day components
    var parts = inputDate.split('-');
    var year = parseInt(parts[0]);
    var month = parseInt(parts[1]) - 1; // Adjusting month to zero-based index
    var day = parseInt(parts[2]);

    // Creating a Date object with the provided date
    var date = new Date(year, month, day);

    // Adding the specified number of days
    date.setDate(date.getDate() + numDays);

    // Formatting the resulting date as 'yyyy-MM-dd'
    var result =
      date.getFullYear() +
      '-' +
      ('0' + (date.getMonth() + 1)).slice(-2) +
      '-' +
      ('0' + date.getDate()).slice(-2);
    return result;
  }

  const handleDurationChange = (e) => {
    if (
      formRef.current?.values.tour_start_date &&
      formRef.current?.values.NoOfNights &&
      e.target.value > 0
    ) {
      const days = parseInt(formRef.current?.values.NoOfNights) + 1;
      const existingDays = state.days.length;

      if (days > existingDays) {
        const extraDays = days - existingDays;

        const result = Array.from({ length: extraDays }, (_, i) => [
          {
            activityId: ``,
            activity: {},
            numberOfAdults: formRef.current?.values?.numberOfAdults,
            numberOfChildren: formRef.current?.values?.numberOfChildren,
            numberOfInfants: formRef.current?.values?.numberOfInfants,
          },
        ]);
        // console.log(days, existingDays, extraDays);
        const finalArr = [...state.days, ...result];
        // console.log(finalArr);
        const endDate = addDaysToDate(
          formRef.current?.values.tour_start_date,
          days - 1,
        );
        formRef.current?.setFieldValue('tour_end_date', endDate);
        formRef.current?.setFieldValue('days', finalArr);
        dispatch({
          type: 'CHANGE_DAYS',
          payload: finalArr,
        });
      } else {
        const extraDays = existingDays - days;

        const finalArr = state.days.slice(extraDays);
        const endDate = addDaysToDate(
          formRef.current?.values.tour_start_date,
          days - 1,
        );
        formRef.current?.setFieldValue('tour_end_date', endDate);
        formRef.current?.setFieldValue('days', finalArr);
        dispatch({
          type: 'CHANGE_DAYS',
          payload: finalArr,
        });
      }
    }
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case 'CHANGE_STATE':
        let stateArr = [];
        stateArr =
          countryData.find((country) => country.name === action.payload)
            .states || [];
        stateArr =
          countryData.find((country) => country.name === action.payload)
            .states || [];
        return { ...state, states: stateArr };
      case 'CHANGE_CITY':
        let cityArr = [];
        cityArr =
          state?.states.find((state) => state.name === action.payload).cities ||
          [];
        cityArr =
          state?.states.find((state) => state.name === action.payload).cities ||
          [];
        return { ...state, cities: cityArr };
      case 'CHANGE_DAYS':
        return { ...state, days: action.payload };
      case 'ADD_ACTIVITY':
        const { index: activityDayIndex, data } = action.payload;
        state.days[activityDayIndex].push(data);
        return { ...state };
      case 'REMOVE_ACTIVITY':
        const { index: deleteIndex } = action.payload;
        state.days[deleteIndex].pop();
        return { ...state };
      case 'CHANGE_SINGLE_DAY':
        let { id, index, idx } = action.payload;

        const activity = activityData.data?.find(
          (x) => x._id.toString() === id.toString(),
        );

        state.days[index][idx].activity = activity;

        return { ...state };
      case 'CHANGE_TRANSFER':
        let { index: id1, idx: id2, transportType } = action.payload;
        state.days[id1][id2].transportType = transportType;
        state.days[id1][id2].AdultCost = 0;
        state.days[id1][id2].ChildCost = 0;
        state.days[id1][id2].InfantCost = 0;
        const adultCount = state.days[id1][id2].numberOfAdults;
        const childCount = state.days[id1][id2].numberOfChildren;
        const infantCount = state.days[id1][id2].numberOfInfants;
        const totalCount = adultCount + childCount + infantCount;

        const totalPrice = getActivityPrice(
          state.days[id1][id2].activity,
          transportType,
          adultCount,
          childCount,
        );
        state.days[id1][id2].TotalCost = totalPrice;

        if (transportType === 'PT') {
          const perPersonCost = totalPrice / totalCount;

          const adultPrice = perPersonCost * adultCount;
          state.days[id1][id2].AdultCost = adultPrice;

          const childPrice = perPersonCost * childCount;
          state.days[id1][id2].ChildCost = childPrice;

          const infantPrice = perPersonCost * infantCount;
          state.days[id1][id2].InfantCost = infantPrice;
        } else {
          const adultPrice = getActivityPrice(
            state.days[id1][id2].activity,
            transportType,
            adultCount,
            0,
          );
          state.days[id1][id2].AdultCost = adultPrice;

          const childPrice = getActivityPrice(
            state.days[id1][id2].activity,
            transportType,
            0,
            childCount,
          );
          state.days[id1][id2].ChildCost = childPrice;

          const infantPrice = 0.0;
          state.days[id1][id2].InfantCost = infantPrice;
        }

        return { ...state };

      case 'UPDATE_PRICES':
        let { adult, child, infant } = action.payload;
        state.days.forEach((element, elementIndex) => {
          element.forEach((el, elIndex) => {
            // console.log('index', elIndex, el);
            el.AdultCost = 0;
            el.ChildCost = 0;
            el.InfantCost = 0;
            const countAdult = adult;
            const countChild = child;
            const countInfant = infant;
            // formRef.current.setFieldValue(
            //   `days.${elementIndex}.${elIndex}.numberOfAdults`,
            //   countAdult,
            // );
            // formRef.current.setFieldValue(
            //   `days.${elementIndex}.${elIndex}.numberOfChildren`,
            //   countChild,
            // );
            // formRef.current.setFieldValue(
            //   `days.${elementIndex}.${elIndex}.numberOfInfants`,
            //   countInfant,
            // );
            el.numberOfAdults = countAdult;
            el.numberOfChildren = countChild;
            el.numberOfInfants = countInfant;
            if (el.activity?._id?.length > 0) {
              let modeOfTransport =
                el?.transportType || el?.activity?.transportType;

              const totalPrice = getActivityPrice(
                el.activity,
                modeOfTransport,
                countAdult,
                countChild,
              );
              const totalPeople = countAdult + countChild + countInfant;

              el.TotalCost = totalPrice;

              if (modeOfTransport === 'PT') {
                const eachPersonCost = totalPrice / totalPeople;

                const adultPrice = eachPersonCost * countAdult;
                el.AdultCost = adultPrice;

                const childPrice = eachPersonCost * countChild;
                el.ChildCost = childPrice;

                const infantPrice = eachPersonCost * countInfant;
                el.InfantCost = infantPrice;
              } else {
                const adultPrice = getActivityPrice(
                  el.activity,
                  modeOfTransport,
                  countAdult,
                  0,
                );
                el.AdultCost = adultPrice;

                const childPrice = getActivityPrice(
                  el.activity,
                  modeOfTransport,
                  0,
                  countChild,
                );
                el.ChildCost = childPrice;

                const infantPrice = 0.0;
                el.InfantCost = infantPrice;

                // const adultPrice =
                //   el.activity.price[modeOfTransport].AdultCost * countAdult;
                // el.AdultCost = adultPrice;

                // const childPrice =
                //   el.activity.price[modeOfTransport].ChildCost * countChild;
                // el.ChildCost = childPrice;

                // const infantPrice =
                //   el.activity.price[modeOfTransport].InfantCost * countInfant;
                // el.InfantCost = infantPrice;
              }
            }
          });
        });
        return { ...state };

      default:
        return state;
    }
  };

  const updateState = () => {
    const days = parseInt(data.duration);

    const result = Array.from({ length: days }, (_, i) => [
      {
        activityId: ``,
        activity: { priceTags: [] },
        numberOfAdults: data.AdultCount,
        numberOfChildren: data.ChildCount,
        numberOfInfants: data.InfantCount,
      },
    ]);

    data.days.forEach((day, dayIndex) => {
      day.forEach((d, dayIdx) => {
        // let priceTags = Object.keys(d.price)
        //   .filter((x) => x !== '_id')
        //   .map((x) => ({
        //     name:
        //       x === 'PT'
        //         ? 'Private Transfer'
        //         : x === 'ST'
        //           ? 'Shared Transfer'
        //           : 'Without Transfer',
        //     type: x,
        //   }));
        let priceTags = Object.keys(d.price)
          .filter((x) => x !== '_id')
          .map((x) => ({
            name:
              x === 'PT' && d.price[x][0].price > 0
                ? 'Private Transfer'
                : x === 'ST' && parseInt(d.price[x].AdultCost) > 0
                  ? 'Shared Transfer'
                  : x === 'WOT' && parseInt(d.price[x].AdultCost) > 0
                    ? 'Without Transfer'
                    : '',
            type: x,
          }))
          .filter((item) => item.name.length > 0);
        const activityDataState = {
          activityId: d.activityId,
          activity: { priceTags, ...d },
          numberOfAdults: data.AdultCount,
          numberOfChildren: data.ChildCount,
          numberOfInfants: data.InfantCount,
          TotalCost: d.totalCost,
          AdultCost: parseFloat(d.AdultCost),
          ChildCost: parseFloat(d.ChildCost),
          InfantCost: parseFloat(d.InfantCost),
          transportType: d.transportType,
        };
        result[dayIndex][dayIdx] = activityDataState;
      });
    });
    formRef.current?.setFieldValue('days', result);
    formRef.current?.setFieldValue('days', result);

    dispatch({ type: 'CHANGE_DAYS', payload: result });
  };

  const [state, dispatch] = useReducer(reducer, {
    states: [],
    cities: [],
    days: [],
  });

  useLayoutEffect(() => {
    updateState();
  }, [data, activityData]);

  return (
    <>
      <ModuleHeader
        headerTitle={data.itineraryName}
        headerImg={data.images[0]}
      />
      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <Typography variant="h4">
            <LocationOnIcon>Location</LocationOnIcon>
            {data.location} - {data.country}
          </Typography>
        </Grid>
      </Grid>
      <br />
      <Formik
        initialValues={INITIAL_VALUES}
        onSubmit={async (values, actions) => {
          const reqBody = _.cloneDeep(values);
          let total_amount = 0.0;
          let total_adult_amount = 0.0;
          let total_child_amount = 0.0;
          let total_infant_amount = 0.0;
          reqBody.days.forEach((day, index) => {
            return day.forEach((d, idx) => {
              d.activityId = state.days[index][idx].activity._id;
              d.numberOfAdults = state.days[index][idx].numberOfAdults;
              d.numberOfChildren = state.days[index][idx].numberOfChildren;
              d.numberOfInfants = state.days[index][idx].numberOfInfants;
              total_amount =
                parseFloat(state.days[index][idx].TotalCost) +
                parseFloat(total_amount);
              total_adult_amount =
                parseFloat(state.days[index][idx].AdultCost) +
                parseFloat(total_adult_amount);
              total_child_amount =
                parseFloat(state.days[index][idx].ChildCost) +
                parseFloat(total_child_amount);
              total_infant_amount =
                parseFloat(state.days[index][idx].InfantCost) +
                parseFloat(total_infant_amount);
              d.totalCost = parseFloat(
                state.days[index][idx].TotalCost || 0.0,
              ).toFixed(2);
              d.AdultCost = parseFloat(
                state.days[index][idx].AdultCost,
              ).toFixed(2);
              d.ChildCost = parseFloat(
                state.days[index][idx].ChildCost,
              ).toFixed(2);
              d.InfantCost = parseFloat(
                state.days[index][idx].InfantCost,
              ).toFixed(2);
              d.activity = {};
            });
          });
          reqBody.total_amount =
            parseFloat(total_amount) +
            parseFloat(reqBody.markup_amount);
          reqBody.total_adult_amount = parseFloat(
            total_adult_amount || 0.0,
          ).toFixed(2);
          reqBody.total_child_amount = parseFloat(
            total_child_amount || 0.0,
          ).toFixed(2);
          reqBody.total_infant_amount = parseFloat(
            total_infant_amount || 0.0,
          ).toFixed(2);

          if (values.visaStatus === true)
            reqBody.locationVisaPrice = allVisas.data.find(
              (x) => x.country === values.country,
            ).price;
          delete reqBody._id;
          if (data.custom === false) {
            reqBody.bookingName = reqBody.itineraryName;
            reqBody.itineraryName = data.itineraryName;
          } else {
            reqBody.bookingName = reqBody.itineraryName;
          }
          await createItinerary(reqBody)
            .unwrap()
            .then(async (res) => {
              toast.success(
                `Itinerary: ${values.itineraryName} Created Successfully`,
                TOAST_CONFIG,
              );
              if (!res.hasOwnProperty('custom') || res.custom === true) {
                await trigger(res.data._id)
                  .unwrap()
                  .then(async (bookingRes) => {
                    const location = allLocations.data.find(
                      (x) => x.locationName === values.location,
                    );
                    await sendBooking({
                      pdfContent: {
                        ...res.data,
                        itineraryInfo: bookingRes,
                        currency: currencies[values.country][0].code,
                        document_required: location.document_required,
                        important_note: location.important_note,
                        terms_n_conditions: location.terms_n_conditions,
                        exclusion: location.exclusion,
                        ...(values.visaStatus === true && {
                          visa: allVisas.data.find(
                            (x) => x.country === values.country,
                          ),
                        }),
                      },
                      Id: currentUser.userId,
                    })
                      .unwrap()
                      .then((res) => {
                        actions.setSubmitting(false);
                        toast.success(`Sent Mail Successfully`, TOAST_CONFIG);
                      })
                      .catch((err) => {
                        actions.setSubmitting(false);
                        toast.error(`Error Occured`, TOAST_CONFIG);
                        console.error(err);
                      });
                    // actions.resetForm();
                  });
              } else {
                actions.setSubmitting(false);
              }
            })
            .catch((err) => {
              actions.setSubmitting(false);
              toast.error(`Error Occured`, TOAST_CONFIG);
              console.error(err);
            });
          // })
          // .catch((err) => {
          //   console.error(err);
          // });
        }}
        innerRef={formRef}
      >
        {({
          values,
          handleChange: formikHandleChange,
          setFieldValue,
          isSubmitting,
        }) => (
          <Form>
            <Grid container spacing={3}>
              <Grid item sm={12} md={4}>
                <Field
                  as={TextField}
                  label="Itinerary name"
                  name="itineraryName"
                  type="text"
                  fullWidth
                  className="mb-3"
                  required
                />
              </Grid>
              <Grid item sm={12} md={4}>
                <Field
                  as={TextField}
                  label="No. of Adults"
                  name="numberOfAdults"
                  id="numberOfAdults"
                  type="number"
                  fullWidth
                  select={true}
                  className="mb-3"
                  onChange={(e) => {
                    setFieldValue('numberOfAdults', e.target.value);
                    dispatch({
                      type: 'UPDATE_PRICES',
                      payload: {
                        adult: e.target.value,
                        child: values.numberOfChildren,
                        infant: values.numberOfInfants,
                      },
                    });
                  }}
                  required
                >
                  {numberOfAdultsArray.map((number) => (
                    <MenuItem key={number} value={number}>
                      {number}
                    </MenuItem>
                  ))}
                </Field>
              </Grid>
              <Grid item sm={12} md={4}>
                <Field
                  as={TextField}
                  label="No. of childs"
                  name="numberOfChildren"
                  type="number"
                  fullWidth
                  select={true}
                  className="mb-3"
                  required
                  onChange={(e) => {
                    setFieldValue('numberOfChildren', e.target.value);
                    dispatch({
                      type: 'UPDATE_PRICES',
                      payload: {
                        adult: values.numberOfAdults,
                        child: e.target.value,
                        infant: values.numberOfInfants,
                      },
                    });
                  }}
                >
                  {numberOfChildArray.map((number) => (
                    <MenuItem key={number} value={number}>
                      {number}
                    </MenuItem>
                  ))}
                </Field>
              </Grid>
              <Grid item sm={12} md={4}>
                <Field
                  as={TextField}
                  label="No. of infant"
                  name="numberOfInfants"
                  type="number"
                  fullWidth
                  select={true}
                  className="mb-3"
                  required
                  onChange={(e) => {
                    setFieldValue('numberOfInfants', e.target.value);
                    dispatch({
                      type: 'UPDATE_PRICES',
                      payload: {
                        adult: values.numberOfAdults,
                        child: values.numberOfChildren,
                        infant: e.target.value,
                      },
                    });
                  }}
                >
                  {numberOfInfantArray.map((number) => (
                    <MenuItem key={number} value={number}>
                      {number}
                    </MenuItem>
                  ))}
                </Field>
              </Grid>
              <Grid item sm={12} md={4}>
                <Field
                  as={TextField}
                  name="tour_start_date"
                  type="date"
                  fullWidth
                  className="mb-3"
                  label="Check In"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item sm={12} md={4}>
                <Field
                  as={TextField}
                  label="Duration in Nights"
                  name="NoOfNights"
                  type="text"
                  fullWidth
                  className="mb-3"
                  onKeyUp={handleDurationChange}
                />
              </Grid>
              <Grid item sm={12} md={4}>
                <Field
                  as={TextField}
                  disabled
                  value={values.tour_end_date}
                  name="tour_end_date"
                  type="date"
                  fullWidth
                  className="mb-3"
                  label="Check Out"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item sm={12} md={4}>
                <Field
                  as={TextField}
                  name="markup_amount"
                  fullWidth
                  className="mb-3"
                  label={`Markup (${currencies[values?.country]?.[0]?.code || ''})`}
                />
              </Grid>
              {!isVisaLoading && (
                <Grid item sm={12}>
                  <div className="flex items-center">
                    <Typography variant="h6" fontWeight={700}>
                      Opt Visa
                    </Typography>
                    <Checkbox
                      ref={checkboxRef}
                      disabled={
                        !values.country ||
                        !allVisas?.data.find((x) => {
                          return x.country === values.country;
                        })
                      }
                      checked={values.visaStatus}
                      value={values.visaStatus}
                      name="visaStatus"
                      onChange={(e) =>
                        setFieldValue('visaStatus', e.target.checked)
                      }
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                    {values.country &&
                      allVisas.data.find((x) => {
                        return x.country === values.country;
                      }) && (
                        <Typography variant="body1">
                          Adult Price:{' '}
                          {
                            allVisas.data.find(
                              (x) => x.country === values.country,
                            ).price.adult_price
                          }
                          , Child Price:{' '}
                          {
                            allVisas.data.find(
                              (x) => x.country === values.country,
                            ).price.child_price
                          }
                          , Infant Price:{' '}
                          {
                            allVisas.data.find(
                              (x) => x.country === values.country,
                            ).price.infant_price
                          }{' '}
                          (Prices in -&nbsp;{currencies[values.country][0].code}
                          )
                        </Typography>
                      )}
                  </div>
                </Grid>
              )}
            </Grid>
            <Divider sx={{ marginY: '0.2rem' }} />
            <Grid container rowSpacing={2}>
              <FieldArray name="days">
                {({ push, insert, remove: removeDay }) => (
                  <>
                    <Grid item sm={8}>
                      <Typography variant="h6" fontWeight={700}>
                        Add Days
                      </Typography>
                    </Grid>
                    {/* <Grid item sm={4}>
                  <Button
                    disableElevation
                    variant="contained"
                    sx={{ float: 'right' }}
                    onClick={() => push([{ id: '' }])}
                  >
                    + Day
                  </Button>
                </Grid> */}
                    {values.days.map((_, index) => (
                      <Grid item key={index} sm={12}>
                        <FieldArray name={`days.${index}`}>
                          {({
                            push: pushActivity,
                            insert: insertActivity,
                            remove: removeActivity,
                            pop: popActivity,
                          }) => (
                            <Box
                              sx={{
                                background: '#EBE4D1',
                                padding: '0.5rem',
                                borderRadius: '12px',
                              }}
                            >
                              <Box
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'end',
                                }}
                              >
                                <Box
                                  sx={{
                                    display: 'flex',
                                  }}
                                >
                                  <Button
                                    disableElevation
                                    variant="contained"
                                    sx={{
                                      float: 'right',
                                    }}
                                    onClick={() => {
                                      const data = {
                                        activityId: ``,
                                        activity: {},
                                        numberOfAdults:
                                          formRef.current?.values
                                            ?.numberOfAdults,
                                        numberOfChildren:
                                          formRef.current?.values
                                            ?.numberOfChildren,
                                        numberOfInfants:
                                          formRef.current?.values
                                            ?.numberOfInfants,
                                        TotalCost: 0.0,
                                      };
                                      dispatch({
                                        type: 'ADD_ACTIVITY',
                                        payload: {
                                          index,
                                          data,
                                        },
                                      });
                                      pushActivity(data);
                                    }}
                                  >
                                    + Activity
                                  </Button>
                                  <Button
                                    disableElevation
                                    variant="contained"
                                    sx={{
                                      float: 'right',
                                    }}
                                    color="error"
                                    onClick={() => {
                                      if (state.days[index].length > 1) {
                                        dispatch({
                                          type: 'REMOVE_ACTIVITY',
                                          payload: {
                                            index,
                                          },
                                        });
                                        popActivity();
                                      }
                                    }}
                                  >
                                    Remove
                                  </Button>
                                </Box>
                              </Box>
                              {values?.days[index] &&
                                values.days[index].length > 0 &&
                                values.days[index].map((_, idx) => {
                                  return (
                                    <Box
                                      sx={{
                                        display: 'flex',
                                        flexDirection: 'column', // Change to column layout
                                        marginBottom: '1rem',
                                      }}
                                      key={idx}
                                    >
                                      <Grid container spacing={2}>
                                        {' '}
                                        <Grid item sm={1} md={4}>
                                          <Typography fontWeight={700}>
                                            Activity: {idx + 1} (Day {index + 1}
                                            :&nbsp;
                                            {addDaysToDate(
                                              values.tour_start_date,
                                              index,
                                            )}
                                            )
                                          </Typography>
                                          <Field
                                            className="custom-select"
                                            name={`days.${index}.${idx}.activityId`}
                                            options={
                                              activityData?.data.filter(
                                                (x) =>
                                                  x.location.toLowerCase() ===
                                                  values.location.toLowerCase(),
                                              ) || []
                                            }
                                            fieldName={'activityName'}
                                            fieldValue={'_id'}
                                            component={CustomSelect}
                                            defaultValue={
                                              state.days?.[index]?.[
                                                idx
                                              ]?.activity?._id?.toString()
                                                ?.length > 0 &&
                                              activityData?.data.find(
                                                (x) =>
                                                  x._id.toString() ===
                                                  state.days?.[index]?.[
                                                    idx
                                                  ]?.activity._id.toString(),
                                              )
                                            }
                                            placeholder="Select Activity"
                                            isMulti={false}
                                            custom={true}
                                            cb={(e) => {
                                              dispatch({
                                                type: 'CHANGE_SINGLE_DAY',
                                                payload: {
                                                  id: e,
                                                  index,
                                                  idx,
                                                },
                                              });

                                              if (
                                                state.days[index][idx]
                                                  .transportType
                                              ) {
                                                dispatch({
                                                  type: 'CHANGE_TRANSFER',
                                                  payload: {
                                                    index,
                                                    idx,
                                                    transportType:
                                                      state.days[index][idx]
                                                        .transportType,
                                                  },
                                                });
                                              }
                                            }}
                                          />
                                        </Grid>
                                        <Grid item sm={2} md={2}>
                                          <Typography fontWeight={700}>
                                            Transfer Option
                                          </Typography>
                                          <Field
                                            className="custom-select"
                                            name={`days.${index}.${idx}.transportType`}
                                            fieldName={'name'}
                                            fieldValue={'type'}
                                            options={
                                              state.days?.[index]?.[idx]
                                                ?.activity?.priceTags || []
                                            }
                                            defaultValue={
                                              state.days?.[index]?.[idx]
                                                ?.activity.transportType && {
                                                name:
                                                  state.days?.[index]?.[idx]
                                                    ?.activity.transportType ===
                                                  'PT'
                                                    ? 'Private Transfer'
                                                    : state.days?.[index]?.[idx]
                                                          ?.activity
                                                          .transportType ===
                                                        'ST'
                                                      ? 'Shared Transfer'
                                                      : 'Without Transfer',
                                                type: state.days?.[index]?.[idx]
                                                  ?.activity.transportType,
                                              }
                                            }
                                            component={CustomSelect}
                                            placeholder="Select Transfer Option"
                                            isMulti={false}
                                            custom={false}
                                            cb={(e) => {
                                              dispatch({
                                                type: 'CHANGE_TRANSFER',
                                                payload: {
                                                  index,
                                                  idx,
                                                  transportType: e,
                                                },
                                              });
                                            }}
                                          />
                                        </Grid>
                                        <Grid item sm={2} md={1}>
                                          <Typography fontWeight={700}>
                                            Adults
                                          </Typography>
                                          <Typography fontWeight={700}>
                                            {
                                              state.days[index][idx]
                                                .numberOfAdults
                                            }
                                          </Typography>
                                        </Grid>
                                        <Grid item sm={2} md={1}>
                                          <Typography fontWeight={700}>
                                            Childrens
                                          </Typography>
                                          <Typography fontWeight={700}>
                                            {
                                              state.days[index][idx]
                                                .numberOfChildren
                                            }
                                          </Typography>
                                        </Grid>
                                        <Grid item sm={2} md={1}>
                                          <Typography fontWeight={700}>
                                            Infants
                                          </Typography>
                                          <Typography fontWeight={700}>
                                            {
                                              state.days[index][idx]
                                                .numberOfInfants
                                            }
                                          </Typography>
                                        </Grid>
                                        <Grid item sm={2} md={2}>
                                          <Typography fontWeight={700}>
                                            Total Price
                                          </Typography>
                                          <Typography
                                            fontWeight={800}
                                            display="flex"
                                          >
                                            {currencies[values.country][0].code}{' '}
                                            {parseFloat(
                                              state.days?.[index]?.[idx]
                                                ?.TotalCost || 0.0,
                                            ).toFixed(2) || 0.0}
                                          </Typography>
                                        </Grid>
                                      </Grid>
                                    </Box>
                                  );
                                })}
                            </Box>
                          )}
                        </FieldArray>
                      </Grid>
                    ))}
                  </>
                )}
              </FieldArray>
            </Grid>
            <Divider sx={{ marginY: '0.7rem' }} />
            <Button
              type="submit"
              variant="contained"
              disableElevation
              disabled={isSubmitting}
            >
              Submit
            </Button>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default CustomizeItinerary;
