import React, { useState } from 'react';
import {
  Box,
  Button,
  Divider,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useGetAllBookingQuery } from '../../../apis/bookingApis/bookingApi';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from '../../../app/store/reducers/authSlice';
import BookingTable from '../../../components/bookingTable/BookingTable';

const Bookings = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [keyword, setKeyword] = useState('');
  const currentUser = useSelector(selectCurrentUser);
  const [{ pageIndex, pageSize }, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const { data, isLoading, isFetching, refetch } = useGetAllBookingQuery(
    {
      keyword: searchParams.get('keyword') || '',
      page: pageIndex,
      limit: pageSize,
    },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  // console.log('all bookings: ', data)

  const handleSearch = () => {
    setSearchParams({ keyword: keyword.toString() });
  };

  const handleChange = (e) => {
    const searchKeyword = e.target.value.split('/');
    searchKeyword.length > 1
      ? setKeyword(searchKeyword[1])
      : setKeyword(searchKeyword[0]);
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box display={'flex'}>
          <Typography variant="h5" fontWeight={700}>
            Confirmed Bookings
          </Typography>
          <button type="submit"
            className="h-full p-1 text-sm font-medium text-white bg-blue-700 border border-blue-700 rounded-e-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300"
            onClick={() => refetch()}
          >Refresh
          </button>
        </Box>
      </Box>
      <Divider role="presentation" sx={{ marginTop: '0.3rem' }} />
      <br />

      <div className='flex mb-2'>
        <input 
          type='search'
          id='Search-dropdown'
          className="block p-2.5 text-sm text-gray-900 bg-gray-50 rounded-l-lg  border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
          placeholder='Booking Id'
          defaultValue={keyword}
          onChange={handleChange}
        />
         <button
          type="submit"
          className=" p-2.5 text-sm font-medium h-full text-white bg-blue-700 rounded-e-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300"
          onClick={handleSearch}
        >
          Search
          <span className="sr-only">Search</span>
        </button>
      </div>
      {!isLoading && !isFetching && data?.data?.length > 0 && (
        <BookingTable
          data={data}
          currentUser={currentUser}
          pageIndex={pageIndex}
          pageSize={pageSize}
          refetch={refetch}
          setPagination={setPagination}
        />
      )}
    </>
  );
};

export default Bookings;
